import SbUtils from '../../../Utils/SbUtils'

import './SinglePost.css'
import Caption from './Elements/Caption'
import Likes from './Elements/Likes';
import Views from './Elements/Views';
import Author from './Elements/Author';
import VideoComponent from './Elements/Video';

const GalleryPlayer = ({ post, headerData, postIndex, feedSettings, isFeedEditor, isPlaying, setIsPlaying }) => {
    const layoutClass = 'sb-post-list-item';
    const playerExperience = 'inline';

    if (headerData?.length > 1) {
        headerData = headerData.filter(header => header.open_id === post.open_id);
    }
    headerData = headerData[0] || headerData;

    return (
        <div className='sb-post-item-wrap sb-gallery-player' key={postIndex}>
            <div className={`sb-post-item sbtt-post-item ${layoutClass}`} key={postIndex}>
                {
                    <div className='sb-post-item-image-wrap'
                        style={{
                            position: 'relative',
                            width: "385px",
                            height: "380px",
                            overflow: 'hidden',
                            borderRadius: '4px',
                        }}
                    >

                        {isPlaying && post?.video_url &&
                            <VideoComponent
                                post={post}
                                playerExperience={playerExperience}
                            />
                        }

                        {!isPlaying &&
                            <img className='sb-post-item-image'
                                src={post?.local_cover_image_url || post?.cover_image_url}
                                onClick={() => {
                                    if (!isFeedEditor) {
                                        setIsPlaying(true);
                                    }
                                }}
                            />
                        }
                    </div>
                }

                <div className='sb-post-item-details'>

                    <Author
                        headerData={headerData}
                    />

                    <Caption
                        post={post}
                        postIndex={postIndex}
                        feedSettings={feedSettings}
                        isFeedEditor={isFeedEditor}
                    />

                    <div className='sb-post-item-meta'>
                        <Views post={post} fillColor={'black'} />

                        <Likes post={post} fillColor={'black'} />
                    </div>

                </div>
            </div>
            {isFeedEditor && SbUtils.addHighlighter('tiktokfeed', 'Posts', postIndex)}
        </div>
    )
}

export default GalleryPlayer;